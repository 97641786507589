   .footer {
    background-color: rgb(255, 255, 255);
    border-top:2px solid rgb(0, 0, 0);
    position: fixed;
    width: 100%;
    bottom: 0;
    color: white;
    font-size: 25px;
   }

   .logo
   {
       color:black;
       
   }