.header
{

    padding: 10px 20px;
    text-align: left;
}

.list
    {
        padding: 0px 60px;
        text-align: left;
    }