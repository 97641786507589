.homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 60px;
    text-align: center;
    font-size: larger;
    justify-content: space-evenly;
    }
 
 