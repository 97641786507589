.navigation {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
  
    .logo-container {
      height: 150%;
      width: 100px;
      padding: 15px;
    }
  
    .nav-links-container {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
  
      .nav-link {
        padding: 10px 15px;
        cursor: pointer;
        color: inherit;
        font-size:larger
     
       
      }
    }
  }